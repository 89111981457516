<template>
  <v-app>
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
            lg="3"
          >
            <v-card class="elevation-12">
              <v-toolbar
                color="green darken-3"
                dark
                flat
              >
                <v-toolbar-title>Register</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form ref="form" v-model="valid">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    label="Password"
                    type="password"
                  ></v-text-field>
                  <v-text-field
                    v-model="passwordConfirmation"
                    :rules="passwordConfirmationRules"
                    type="password"
                    label="Password confirmation"
                  ></v-text-field>
                </v-form>
                <div align="center" style="color: white;">
                  Your account needs to be validated by an administrator before you can use the website
                </div>
                <v-btn :disabled="!valid" class="mt-3" width="100%" color="green darken-3" @click="handleSubmit">Register</v-btn>
                <v-divider class="my-5"></v-divider>
                <div align="center">
                  Already have an account ?
                  <router-link to="/login">
                    <span>Sign in</span>
                  </router-link>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-snackbar :timeout="10000" v-model="snackbar" bottom right absolute color="error">
          {{error}}
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';

export default {
  metaInfo: {
    title: 'Register'
  },
  props: ['nextUrl'],
  data () {
    return {
      valid: true,
      error: '',
      snackbar: false,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      password: '',
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password should be at least 6 characters'
      ],
      passwordConfirmationRules: [
        v => !!v || 'Password confirmation is required',
        v => (v && v === this.password) || 'Password do not match'
      ],
      passwordConfirmation: ''
    };
  },
  methods: {
    handleSubmit (e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        firebase.auth().createUserWithEmailAndPassword(this.email, this.password).then(credentials => {
          let expires = new Date();
          expires.setDate(expires.getDate() + 30);
          firebase.firestore().collection('users').doc(credentials.user.uid).set({
            enabled: false,
            user: {
              uid: credentials.user.uid,
              displayName: credentials.user.displayName,
              email: credentials.user.email
            },
            plan: {
              type: 'Trial',
              createdAt: new Date(),
              modifiedAt: new Date(),
              expiresAt: expires
            }
          }).then(() => {
            this.$router.replace('/');
          });
        }).catch((error) => {
          this.error = error.message;
          this.snackbar = true;
        });
      }
    }
  },
  mounted () {
    if (firebase.auth().currentUser) {
      this.$router.replace('/');
    }
  }
};
</script>
